import { Apollo } from 'apollo-angular';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { RightRailsQuery, RightRailsHOEvidenceQuery, RightRailsEdocsQuery } from '../graphql/rightrails-schema';
import * as i0 from "@angular/core";
import * as i1 from "apollo-angular";
var RightRailService = /** @class */ (function () {
    function RightRailService(apollo) {
        this.apollo = apollo;
        this.showAgentInfo$ = new BehaviorSubject(false);
        this.agentInfoLoaded$ = new BehaviorSubject(false);
    }
    RightRailService.prototype.quickLinkQuery = function (policyNumber) {
        return this.apollo
            .watchQuery({
            query: RightRailsQuery,
            variables: {
                policyNumber: policyNumber
            }
        })
            .valueChanges.pipe(map(function (_a) {
            var data = _a.data, loading = _a.loading;
            console.log("Right Rail Loading : ", loading);
            var response = data.rightRail.quickLinks;
            if (data.rightRail.showEdocs) {
                response.showEdocs = data.rightRail.showEdocs;
            }
            return response;
        }));
    };
    RightRailService.prototype.getQuickLinks = function (policyNumber) {
        return this.quickLinkQuery(policyNumber).pipe(map(function (result) {
            return result;
        }));
    };
    RightRailService.prototype.getFlagsFromCache = function (policyNumber) {
        return this.apollo.watchQuery({
            query: RightRailsQuery,
            fetchPolicy: 'cache-first',
            variables: {
                policyNumber: policyNumber
            }
        }).valueChanges.pipe(map(function (_a) {
            var data = _a.data;
            if (data && data.rightRail && data.rightRail && data.rightRail.quickLinks) {
                return data.rightRail.quickLinks.showWallet;
            }
        }));
    };
    RightRailService.prototype.getEdocs = function (policyNumber) {
        return this.apollo
            .watchQuery({
            query: RightRailsEdocsQuery,
            variables: {
                policyNumber: policyNumber
            }
        })
            .valueChanges.pipe(map(function (_a) {
            var data = _a.data;
            return data.rightRail.showEdocs;
        }));
    };
    RightRailService.prototype.getHomeEvidence = function (policyNumber) {
        return this.apollo
            .watchQuery({
            query: RightRailsHOEvidenceQuery,
            variables: {
                policyNumber: policyNumber
            }
        })
            .valueChanges.pipe(map(function (_a) {
            var data = _a.data;
            return data.rightRail.showHomeEvidence;
        }));
    };
    RightRailService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RightRailService_Factory() { return new RightRailService(i0.ɵɵinject(i1.Apollo)); }, token: RightRailService, providedIn: "root" });
    return RightRailService;
}());
export { RightRailService };
