import { OnInit } from '@angular/core';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { USER_ID, GUEST_USER_ID } from '@prcins/constants';
import { Router, ActivatedRoute } from '@angular/router';
import { PolicyProviderService } from '../services/policy-provider.service';
import { AdminService } from '../services/admin.service';
import { LoginService } from '@prcins/utils';
import { UploadAdminDocsService } from '../services/upload-admindocs.service';
import { catchError } from 'rxjs/operators';
import * as _ from 'lodash';
import { of } from 'rxjs';
var AdminHomeComponent = /** @class */ (function () {
    function AdminHomeComponent(fb, policyService, loginService, adminService, router, route, uploadDocsService) {
        var _this = this;
        this.fb = fb;
        this.policyService = policyService;
        this.loginService = loginService;
        this.adminService = adminService;
        this.router = router;
        this.route = route;
        this.uploadDocsService = uploadDocsService;
        this.showSpinner = false;
        this.showUnsupportedFile = false;
        this.showUnsupportedFileSize = false;
        this.supportedFiles = ['csv', 'CSV'];
        this.supportedFilesList = ".csv, .CSV";
        this.adminActivityForm = this.fb.group({
            activity: ['policyNumber'],
            activityInput: this.fb.array([])
        });
        this.policyInput = new FormControl('', [Validators.required]);
        this.emailInput = new FormControl('', [
            Validators.required,
            Validators.email,
            Validators.pattern('^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+.[A-Za-z]{2,4}$')
        ]);
        this.uploadInput = new FormControl('', [Validators.required]);
        this.adminService.adminActivityError.subscribe(function (data) {
            _this.notification = data;
        });
    }
    AdminHomeComponent.prototype.ngOnInit = function () {
        var _this = this;
        sessionStorage.removeItem(GUEST_USER_ID);
        this.selectedActivity = this.activity.value;
        this.onActivityChange();
        this.adminActivityForm.get('activity').valueChanges.subscribe(function (val) {
            _this.selectedActivity = val;
            _this.onActivityChange();
        });
        this.adminService.validateSuperAdmin().subscribe(function (data) {
            _this.superUser = data ? data : false;
        });
    };
    Object.defineProperty(AdminHomeComponent.prototype, "activityInput", {
        get: function () {
            return this.adminActivityForm.get('activityInput');
        },
        set: function (value) { },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AdminHomeComponent.prototype, "activity", {
        get: function () {
            return this.adminActivityForm.get('activity');
        },
        enumerable: true,
        configurable: true
    });
    AdminHomeComponent.prototype.onActivityChange = function () {
        var _this = this;
        switch (this.selectedActivity) {
            case "policyNumber":
                this.arrayInput = [];
                this.arrayInput.push({
                    name: this.selectedActivity,
                    label: "Policy Number",
                    formControl: this.policyInput,
                    controlType: "text"
                });
                this.activityInput.clear();
                this.arrayInput.map(function (_a) {
                    var formControl = _a.formControl;
                    console.log(formControl);
                    _this.activityInput.push(formControl);
                });
                break;
            case "email":
                this.arrayInput = [];
                this.arrayInput.push({
                    name: this.selectedActivity,
                    label: "Email",
                    formControl: this.emailInput,
                    controlType: "text"
                });
                this.activityInput.clear();
                this.arrayInput.map(function (_a) {
                    var formControl = _a.formControl;
                    console.log(formControl);
                    _this.activityInput.push(formControl);
                });
                break;
            case "bulkInviteUpload":
                this.arrayInput = [];
                this.arrayInput.push({
                    name: this.selectedActivity,
                    label: "CSV Document",
                    formControl: this.uploadInput,
                    controlType: "file"
                });
                this.activityInput.clear();
                this.arrayInput.map(function (_a) {
                    var formControl = _a.formControl;
                    console.log(formControl);
                    _this.activityInput.push(formControl);
                });
                break;
            case "endorsementBlockUpload":
                this.arrayInput = [];
                this.arrayInput.push({
                    name: this.selectedActivity,
                    label: "CSV Document",
                    formControl: this.uploadInput,
                    controlType: "file"
                });
                this.activityInput.clear();
                this.arrayInput.map(function (_a) {
                    var formControl = _a.formControl;
                    console.log(formControl);
                    _this.activityInput.push(formControl);
                });
                break;
        }
    };
    // On file Select
    AdminHomeComponent.prototype.onFileChange = function (event) {
        this.file = event.target.files[0];
        this.currentInputElement = event;
    };
    AdminHomeComponent.prototype.onSubmit = function () {
        var _this = this;
        this.showSpinner = true;
        var _a = this.adminActivityForm.value, activity = _a.activity, userInput = _a.activityInput;
        switch (activity) {
            case 'policyNumber':
                this.policyService.getUserName(userInput[0].toUpperCase()).subscribe(function (email) {
                    if (email) {
                        sessionStorage.setItem(USER_ID, email);
                    }
                    else {
                        sessionStorage.setItem(GUEST_USER_ID, userInput[0]);
                    }
                    _this.router.navigate(['eservice/home']);
                    _this.showSpinner = false;
                }, function (error) { _this.notification = { type: 'ERROR', message: "Policy Not Available" }; }, function () { _this.showSpinner = false; });
                break;
            case 'email':
                var emailAddress_1 = userInput[0];
                this.loginService.getActiveAccount(emailAddress_1).subscribe(function (accountInfo) {
                    if (accountInfo.active) {
                        _this.router.navigate(['eservice/admin/activity/delete'], { queryParams: { email: emailAddress_1 } });
                    }
                    else {
                        _this.notification = { type: 'ERROR', message: "Email Account Not Found" };
                    }
                    _this.showSpinner = false;
                });
                break;
            case 'bulkInviteUpload':
                this.uploadBulkInvitationDocument(activity);
                break;
            case 'endorsementBlockUpload':
                this.uploadEndorsementBlockDocument(activity);
                break;
        }
    };
    AdminHomeComponent.prototype.uploadBulkInvitationDocument = function (docType) {
        var _this = this;
        var fileName = this.file.name;
        var fileExtension = "";
        if (_.lastIndexOf(fileName, ".") > 0 && fileName) {
            fileExtension = fileName.substring(_.lastIndexOf(fileName, ".") + 1, fileName.length);
        }
        else {
            this.showSpinner = false;
            return;
        }
        if (_.includes(this.supportedFiles, fileExtension)) {
            if (this.file) {
                var fileSize = this.file.size / 1024 / 1024;
                if (fileSize > 25) {
                    this.showSpinner = false;
                    this.showUnsupportedFileSize = true;
                    return;
                }
                var formData = new FormData();
                formData.append('file', this.file);
                formData.append('docType', docType);
                formData.append('fileExtension', fileExtension);
                this.uploadDocsService.uploadBulkInviteDocument(formData).subscribe(function (res) {
                    if (res.upload === 'success') {
                        _this.notification = {
                            type: "INFO",
                            message: docType.toUpperCase() + ": File staged and will be processed"
                        };
                        _this.docStatus = res.data;
                        _this.showSpinner = false;
                    }
                    if (res.error === 'UNSUPPORTED') {
                        _this.showSpinner = false;
                        _this.showUnsupportedFile = true;
                    }
                    if (res.error === 'UNSUPPORTED-SIZE') {
                        _this.showSpinner = false;
                        _this.showUnsupportedFileSize = true;
                    }
                    if (res.error === 'SERVER-ERROR') {
                        _this.showSpinner = false;
                        _this.notification = {
                            type: "WARN",
                            message: "Error Uploading the document."
                        };
                    }
                }, function (error) {
                    _this.showSpinner = false;
                    _this.notification = {
                        type: "WARN",
                        message: "Error Uploading the document."
                    };
                });
            }
            else {
                this.showSpinner = false;
            }
        }
        else {
            this.showUnsupportedFile = true;
            this.showSpinner = false;
        }
    };
    AdminHomeComponent.prototype.uploadEndorsementBlockDocument = function (docType) {
        var _this = this;
        var fileName = this.file.name;
        var fileExtension = "";
        if (_.lastIndexOf(fileName, ".") > 0 && fileName) {
            fileExtension = fileName.substring(_.lastIndexOf(fileName, ".") + 1, fileName.length);
        }
        else {
            this.showSpinner = false;
            return;
        }
        if (_.includes(this.supportedFiles, fileExtension)) {
            if (this.file) {
                var fileSize = this.file.size / 1024 / 1024;
                if (fileSize > 25) {
                    this.showSpinner = false;
                    this.showUnsupportedFileSize = true;
                    return;
                }
                var formData = new FormData();
                formData.append('file', this.file);
                this.uploadDocsService.uploadEndorsementBlockDocument(formData).pipe(catchError(function (error) {
                    // handle the error
                    console.error(error);
                    // return an observable with a user-facing error message
                    var errMsg = "There was an error uploading the file. Possible Reason:" + error.error.messageDescription;
                    return of({ "errMessage": errMsg });
                })).subscribe(function (res) {
                    if (res.message) {
                        _this.notification = {
                            type: "INFO",
                            message: res.message
                        };
                    }
                    if (res.errMessage) {
                        _this.notification = {
                            type: "WARN",
                            message: "Error Uploading the document."
                        };
                    }
                });
            }
        }
        else {
            this.showUnsupportedFile = true;
        }
        this.showSpinner = false;
    };
    AdminHomeComponent.prototype.retryUpload = function () {
        var target = this.currentInputElement.target || this.currentInputElement.srcElement;
        target.click();
        this.showUnsupportedFile = false;
        this.showUnsupportedFileSize = false;
    };
    AdminHomeComponent.prototype.closeUnsupportedFilePopUp = function () {
        this.showUnsupportedFile = false;
        this.showUnsupportedFileSize = false;
    };
    return AdminHomeComponent;
}());
export { AdminHomeComponent };
