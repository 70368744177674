import { HttpClient } from '@angular/common/http';
import { BillingDetailsQuery, PolicyInfoQuery, PolicySearchQuery, PolicyUsernameQuery, BalanceDetailsQuery, MobileRegistrationQuery, PolicyCrossSellPromotionsQuery } from '@prcins/utils';
import { Apollo } from 'apollo-angular';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { POLICY_URL, ROADREWARD_URL } from '@prcins/constants';
import * as i0 from "@angular/core";
import * as i1 from "apollo-angular";
import * as i2 from "@angular/common/http";
var PolicyProviderService = /** @class */ (function () {
    function PolicyProviderService(apollo, http) {
        this.apollo = apollo;
        this.http = http;
    }
    PolicyProviderService.prototype.getPolicyInquiry = function (policyNumber) {
        return this.apollo
            .watchQuery({
            query: PolicySearchQuery,
            variables: {
                policyNumber: policyNumber
            } //,
            //fetchPolicy: 'no-cache'
        })
            .valueChanges.pipe(map(function (_a) {
            var data = _a.data;
            return data;
        }));
    };
    PolicyProviderService.prototype.getCrossSellPromotions = function (policyNumber) {
        return this.apollo
            .watchQuery({
            query: PolicyCrossSellPromotionsQuery,
            variables: {
                policyNumber: policyNumber
            }
        })
            .valueChanges.pipe(map(function (_a) {
            var data = _a.data;
            return data;
        }));
    };
    PolicyProviderService.prototype.billingQuery = function (policyNumber) {
        return this.apollo.watchQuery({
            query: BillingDetailsQuery,
            variables: {
                policyNumber: policyNumber
            }
        }).valueChanges;
    };
    PolicyProviderService.prototype.balanceDetails = function (policyNumber) {
        return this.apollo.watchQuery({
            query: BalanceDetailsQuery,
            variables: {
                policyNumber: policyNumber
            }
        }).valueChanges;
    };
    PolicyProviderService.prototype.getBillingDetails = function (policyNumber) {
        var _this = this;
        return this.billingQuery(policyNumber).pipe(map(function (result) {
            var billingDetails = result.data.billingDetails;
            if (billingDetails) {
                //this.cachedBillingData[policyNumber] = true;
                billingDetails.lastPaymentAmnt = _this.ifPresent(billingDetails.lastPaymentAmnt).replace(',', '');
                billingDetails.nextPaymentAmnt = _this.ifPresent(billingDetails.nextPaymentAmnt).replace(',', '');
                billingDetails.pastDueAmnt = _this.ifPresent(billingDetails.pastDueAmnt).replace(',', '');
                billingDetails.balance = _this.ifPresent(billingDetails.balance).replace(',', '');
            }
            return billingDetails;
        }));
    };
    PolicyProviderService.prototype.ifPresent = function (value) {
        if (!value) {
            return '';
        }
        return value;
    };
    PolicyProviderService.prototype.policyGreetingDetails = function (policyNumber) {
        return this.apollo.watchQuery({
            query: PolicyInfoQuery,
            variables: {
                policyNumber: policyNumber
            }
        }).valueChanges;
    };
    PolicyProviderService.prototype.getPolicyGreetingDetails = function (policyNumber) {
        return this.policyGreetingDetails(policyNumber).pipe(map(function (result) { return result.data.policySearchInfo; }));
    };
    PolicyProviderService.prototype.getUserName = function (policyNumber) {
        return this.apollo
            .watchQuery({
            query: PolicyUsernameQuery,
            variables: {
                policyNumber: policyNumber
            }
        })
            .valueChanges.pipe(map(function (_a) {
            var data = _a.data;
            return data.username || null;
        }));
    };
    PolicyProviderService.prototype.getPayloadForBillingAlerts = function (policyNumber) {
        return this.apollo
            .watchQuery({
            query: MobileRegistrationQuery,
            variables: {
                policyNumber: policyNumber
            }
        })
            .valueChanges.pipe(map(function (_a) {
            var data = _a.data;
            return data;
        }));
    };
    PolicyProviderService.prototype.linkPolicy = function (payload, userId, userType) {
        return this.http.post(POLICY_URL.addPolicy + "/" + userId + "/" + userType, payload);
    };
    PolicyProviderService.prototype.getRRDriversDetails = function (policyNumber) {
        return this.http.get(ROADREWARD_URL.DriverDetails + '/' + policyNumber).pipe(map(function (response) {
            return { errorCode: null, data: { drivers: response } };
        }), catchError(function (error) {
            return of({ errorCode: "RR-FAILURE", data: {} });
        }));
    };
    PolicyProviderService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PolicyProviderService_Factory() { return new PolicyProviderService(i0.ɵɵinject(i1.Apollo), i0.ɵɵinject(i2.HttpClient)); }, token: PolicyProviderService, providedIn: "root" });
    return PolicyProviderService;
}());
export { PolicyProviderService };
