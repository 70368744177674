import { HttpClient } from '@angular/common/http';
import { ADMIN_URL } from '@prcins/constants';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var HolidayListService = /** @class */ (function () {
    function HolidayListService(http) {
        this.http = http;
        this.apiUrl = "" + ADMIN_URL.holidays;
    }
    HolidayListService.prototype.getHolidays = function () {
        return this.http.get(this.apiUrl);
    };
    HolidayListService.prototype.saveHoliday = function (holidayList) {
        return this.http.post(this.apiUrl, holidayList);
    };
    HolidayListService.prototype.deleteHoliday = function (holidayDate) {
        return this.http.delete(this.apiUrl + "/" + holidayDate);
    };
    HolidayListService.prototype.saveHolidays = function (holidayList) {
        return this.http.post(this.apiUrl, holidayList);
    };
    HolidayListService.prototype.checkHoliday = function () {
        return this.http.get(this.apiUrl + "/check");
    };
    HolidayListService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function HolidayListService_Factory() { return new HolidayListService(i0.ɵɵinject(i1.HttpClient)); }, token: HolidayListService, providedIn: "root" });
    return HolidayListService;
}());
export { HolidayListService };
var HolidayList = /** @class */ (function () {
    function HolidayList() {
    }
    return HolidayList;
}());
export { HolidayList };
