import { Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ADMIN_URL } from '@prcins/constants';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var AdminService = /** @class */ (function () {
    function AdminService(http) {
        this.http = http;
        this.adminActivityError = new Subject();
    }
    AdminService.prototype.disableAccount = function (email, policyNumber) {
        var url;
        if (policyNumber) {
            url = ADMIN_URL.disableAccount + "/" + email + "/idg_user_group/" + policyNumber;
        }
        else {
            url = ADMIN_URL.disableAccount + "/" + email + "/idg_user_group/null";
        }
        return this.http.delete(url, {});
    };
    AdminService.prototype.validateSuperAdmin = function () {
        var url = ADMIN_URL.validateSuperAdmin + "?name=superUser";
        return this.http.get(url);
    };
    AdminService.prototype.requestAllRegisteredPolicies = function (userId) {
        return this.http.get(ADMIN_URL.getAllRegisteredPolicies + "/" + userId);
    };
    /* Update the Policy status when changed thorugh admin login*/
    AdminService.prototype.updatePolicyStatus = function (accountLookUpInfo) {
        return this.http.put("" + ADMIN_URL.updatePolicyStatus, accountLookUpInfo);
    };
    AdminService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AdminService_Factory() { return new AdminService(i0.ɵɵinject(i1.HttpClient)); }, token: AdminService, providedIn: "root" });
    return AdminService;
}());
export { AdminService };
